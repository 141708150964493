import { themecolors } from "./themevariable";

let APIURL = "http://10.10.12.54:8082/";
let WIDGET_URL = "http://wl-devl.airbookone.com/ja";
if (window.location.host === "abc.airbookone.com") {
  APIURL = "https://dev-abone-api.airbookone.com/";
  WIDGET_URL = "http://devl.airbookone.com/ja";
}
if (window.location.host === "wl-abc.airbookone.com") {
  APIURL = "https://wl-dev-abone-api.airbookone.com/";
  WIDGET_URL = "http://wl-devl.airbookone.com/ja";
}
if (window.location.host === "ja.airbookone.com") {
  APIURL = "https://api-v2.airbookone.com/";
  WIDGET_URL = "http://partner.airbookone.com/ja";
}
export const API_PATH = APIURL;
export const WIDGET_PATH = WIDGET_URL;

// export function validateDomain() {
//   let loc = window.location;

//   // Early check to prevent rendering before redirection
//   if (
//     loc.host === "abc.airbookone.com" ||
//     loc.host === "wl-abc.airbookone.com" ||
//     loc.host === "ja.airbookone.com"
//   ) {
//     let pathname = loc.pathname;

//     // Redirect immediately if the pathname is incorrect
//     if (!pathname.startsWith("/choose-flight")) {
//       // Use window.location.replace to avoid adding the redirect to the browser history
//       window.location.replace(WIDGET_URL);
//       return false; // Prevent further execution
//     } else {
//       console.log("777");
//       window.onbeforeunload = null;
//       return true;
//     }
//   }
// }

export function validateDomain() {
  let loc = window.location;
  let pathname = loc.pathname;
  if (
    loc.host === "abc.airbookone.com" ||
    loc.host === "wl-abc.airbookone.com" ||
    loc.host === "ja.airbookone.com"
  ) {
    // Redirect immediately if the pathname does not include "choose-flight"
    if (!pathname.includes("/choose-flight/")) {
      window.location.replace(WIDGET_URL);
      return false; // Prevent further execution
    } else {
      window.onbeforeunload = null;
      return true;
    }
  } else {
    if (!pathname.includes("/choose-flight/")) {
      window.location.replace(WIDGET_URL);
      return false; // Prevent further execution
    } else {
      window.onbeforeunload = null;
      return true;
    }
  }
}

export function getUrlSegment(index) {
  let pathname = window.location.pathname;

  let segments = pathname.split("/");

  return segments[index] ? segments[index] : "";
}

export function getEleAttribute(attr, theme) {
  // let themeId = getUrlSegment(2) ? atob(getUrlSegment(2)) : "default";
  // theme = themecolors[themeId];
  let defaultTheme = themecolors.default;
  return theme && theme[attr] ? theme[attr] : defaultTheme[attr];
}

export function getSearchParams(name) {
  let searchVal = "";

  let search = window.location.search ? window.location.search : "";
  search = search.replace("?", "");

  let segments = search.split("&");

  let keyValArr = segments.filter((seg) => seg.startsWith(name));
  if (keyValArr.length > 0) {
    searchVal = keyValArr[0].replace(name + "=", "");
  }

  return searchVal;

  // return segments[index] ? segments[index] : '';
}

export function alphaSpaceOnly(str) {
  return /^[A-Za-z\s]*$/.test(str);
}

export function formatCurrency(amount, currency = "USD") {
  return new Intl.NumberFormat("en-US", {
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}

import React from "react";
import panelFlightIcon from "../../assets/icons/flight-icon.png";
import panelEditIcon from "../../assets/icons/edit-icon.png";
import { useHistory } from "react-router-dom";
import { MULTI_CITY } from "../../middleware/constants/trip.constants";
import { getEleAttribute, getSearchParams, API_PATH } from "./utility";

function ChooseAFlightHeader({ searchOptions = {}, theme, referralUrl="" }) {
  let history = useHistory();
  let d_from = getSearchParams("d_from_code");
  let a_at = getSearchParams("a_at_code");

  // const backToWidget = () => {
  //   // Remove unsaved changes warning, if any
  //   window.onbeforeunload = null;

  //   // Get the current URL query string
  //   let curLoc = window.location.search;

  //   // Navigate to the widget path without showing a confirmation dialog
  //   window.location.href = WIDGET_PATH + curLoc;
  // };
  const backToWidget = async() => {

    let res = localStorage.getItem("searchCriteria");
    let operatorId = JSON.parse(res).operatorId
    let emailId = JSON.parse(res).email
    let userId = ""
    let params = {
      emailId,
      operatorId
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    const apiUrl = `${API_PATH}white_label/user-info`;
    await fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
       userId = result.userId
      })
      .catch((error) => {
        console.log("error", error);
        // setLoader(false);
      });
    if(userId!==""){
      window.onbeforeunload = null;
      let curLoc = window.location.search;

      // Ensure ret_date and other params are correctly handled
      if (curLoc.includes("ret_date=undefined")) {
        curLoc = curLoc.replace("ret_date=undefined", "ret_date="); // Remove undefined ret_date
      }
      window.location.href = referralUrl + curLoc + `&userId=${userId}`;
    }
  };
  return (
    <div
      className="ChooseAFlightHeader"
      // style={{
      //   background: getEleAttribute("searchPanelBackgroundColor", theme),
      // }}
    >
      {/* <h1>Book a Flight</h1> */}
      <div
        className="route-info-container"
        onClick={() => backToWidget()}
        style={{ background: getEleAttribute("customPrimaryColor", theme) }}
      >
        <div className="panel-icon-box">
          <div className="panel-icon-flight-vector">
            <img src={panelFlightIcon} alt={"panel-flight=icon"} />
          </div>
        </div>
        {searchOptions?.routeType !== MULTI_CITY && (
          <div className="route-info">
            <p className="airport">({d_from})</p>
            <p className="suffix">TO</p>
            <p className="airport">({a_at})</p>
          </div>
        )}
        {searchOptions?.routeType === MULTI_CITY && (
          <div className="route-info">
            <p className="airport">({d_from})</p>
            <p className="suffix">TO</p>
            <p className="airport">({a_at})</p>
          </div>
        )}
        {/* <div className="circle-filled">
          <img src={EditCircleFilled} alt="edit-circle-filled" />
        </div> */}
        <div className="panel-icon-box">
          <div className="panel-icon-edit-vector">
            <img src={panelEditIcon} alt={"panel-edit-icon"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseAFlightHeader;

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./Modal.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import flight1 from "../../../assets/custom-theme/flight1.png";
import flight2 from "../../../assets/custom-theme/flight2.jpg";
import flight3 from "../../../assets/custom-theme/flight3.png";
import LoadingOverlay from "react-loading-overlay";

export default function Gallery(props) {
  const [gLoader, setGLoader] = useState(true);
  const [show, setShow] = useState(props.show);
  const [itemInd, setInd] = useState(0);
  const [flightInfo, setFlightInfo] = useState(props.flight);
  const [galImages, setGalImages] = useState([]);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    setFlightInfo(props.flight);
    let photos = props.flight.tailPhotos || [];

    let flImg = "";
    if (photos.length > 0) {
      // let filImgArr = photos.map( item => {
      //   let copies=  item.copies.filter( item =>item.size === "LARGE");
      //   return copies.length >0 ? copies[0] : [];
      // });

      let filImgArr = photos;

      setGalImages(filImgArr);
    }
  }, [props.flight]);

  useEffect(() => {
    setGLoader(false);
  }, [galImages]);

  const sliderChange = (e) => {
    let val = e.target.value;
    setInd(parseInt(val));
  };

  const closeGal = () => {
    props.closeGallery(false);
    setInd(0);
  };

  return (
    <>
      {/* {values.map((v, idx) => (
        <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
          Full screen
          {typeof v === 'string' && `below ${v.split('-')[0]}`}
        </Button>
      ))} */}
      <Modal
        show={show}
        fullscreen={"true"}
        onHide={() => closeGal()}
        dialogClassName="modal-100w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="title_ctr">
              <div className="back_btn" onClick={() => closeGal()}>
                {" "}
                &lt; Back
              </div>
              {flightInfo.hasOwnProperty("modelDescription") &&
                flightInfo.modelDescription !== null && (
                  <h3 className="gal_title">{flightInfo.modelDescription}</h3>
                )}

              {/* {flightInfo.hasOwnProperty("aircraftTail") &&
              <div className="gal_subtitle">Tail #{flightInfo.aircraftTail}</div>
    } */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="img-wrapper">
            <>
              <Carousel
                autoPlay={false}
                selectedItem={itemInd}
                showThumbs={false}
              >
                {galImages.length > 0 &&
                  galImages.map((item, gInd) => {
                    return (
                      <div className="img-cont" key={`gal-img-${gInd}`}>
                        <img src={item} />
                      </div>
                    );
                  })}
                {galImages.length === 0 && <>No aircraft photos found...</>}
              </Carousel>
            </>
          </div>
          {galImages.length > 1 && (
            <input
              type="range"
              name="itemInd"
              defaultValue={0}
              min="0"
              step="1"
              max={galImages.length - 1}
              onChange={(e) => sliderChange(e)}
              style={{ border: "none", outline: "none" }}
            />
          )}

          {/* <div className='img-show'>
          </div> */}
          <LoadingOverlay
            active={gLoader}
            spinner
            text="Loading aircraft images"
            fadeSpeed="1000"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
